<script setup lang="ts">
import { computed, type PropType, ref } from 'vue';
import {
  CustomButton,
  WisionLogo,
  LoadingSpinner,
  Combobox
} from '@wision/ui';
import { toast } from 'vue3-toastify';
import { useI18n } from 'vue-i18n';
import { useSetCurrentOrganization } from '@wision/supabase';

const apiClient = useApi();
const { t } = useI18n();

const props = defineProps({
  userFullName: {
    type: String,
    required: true,
  },
  accounts: {
    type: Array as PropType<{ id: string; name: string, xtoolId: number }[]>,
    required: true,
  },
});

const selectedAccount = ref();

const { mutate: updateOrg, isLoading } = useSetCurrentOrganization({
  onSuccess: async () => {
    const selectedAccountXtoolId = props.accounts.find(account => account.id === selectedAccount.value)?.xtoolId;

    if (selectedAccountXtoolId !== undefined)
      await apiClient.user.updateLoginHistory(selectedAccountXtoolId);
    window.location.href = '/';
  },
  onError: () => {
    toast(t('common.somethingwentwrong'), {
      autoClose: 2000,
      type: 'error',
      theme: 'dark',
    });
  },
});

const options = computed(() =>
  props.accounts
    .map((account) => ({
      label: account.name,
      value: account.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
);

const handleSelectAccount = () => {
  if (selectedAccount.value)
    updateOrg(selectedAccount.value);
};
</script>

<template>
  <div>
    <WisionLogo
      :width="64"
      class="mt-5"
    />
    <h2 class="text-center text-2xl font-thin mt-2">
      {{ $t('login.welcome') }}
    </h2>
    <p class="text-center">
      {{ userFullName }}
    </p>
    <div class="mt-10 flex flex-col gap-4">
      <div class="z-[400]">
        <p class="text-gray-300 font-thin">
          {{ $t('login.account.title') }}
        </p>
        <Combobox
          v-model="selectedAccount"
          :options="options"
          :place-holder="$t('login.account.select')"
        />
      </div>
      <CustomButton
        class="w-full z-40"
        :types="['border', 'auto-width']"
        @click="handleSelectAccount"
      >
        <LoadingSpinner
          v-if="isLoading"
          class="h-4"
        />
        <div v-else>
          {{ $t('login.account.continue') }}
        </div>
      </CustomButton>
    </div>
  </div>
</template>
